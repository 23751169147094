@import "./variables.less";

[data-icon] {
  display: inline-flex;
  width: 1em;
  height: 1em;
  vertical-align: top;

  &.-size-20 {
    font-size: 20px;
  }
}

[data-view] {
  > * {
    display: none;
  }

  &[data-view="Example"] {
    height: 300px;
  }

  &[data-view="MobileHeader"] {
    height: 64px;
  }

  &[data-view="HomeNoticeBlock"] {
    min-height: 40vh;
  }
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  height: 56px;
  gap: 8px;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  background-color: @color-blue;
  color: @color-white;
  text-decoration: none;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    background-color: @color-blue-d1;
    color: @color-white;
  }

  > [data-icon] {
    font-size: 24px;
    margin-left: -8px;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.-variant-outline {
    border: 2px solid @color-blue;
    background-color: @color-white;
    color: @color-blue;

    &:hover {
      background-color: @color-blue-l3;
    }
  }

  &.-variant-grey {
    background-color: @color-grey-l2;
    color: @color-grey-d2;

    &:hover {
      background-color: @color-grey-l1;
    }
  }

  &.-size-small {
    height: 48px;
  }

  &.-size-xsmall {
    height: 40px;
    font-size: 16px;
    padding: 0 16px;

    > [data-icon] {
      font-size: 20px;
    }
  }
}

.ButtonGroup {
  display: flex;
  align-items: center;
  gap: 16px;
}

.Divider {
  border: none;
  border-top: 1px solid @color-grey;
  margin: 0;
}

.Heading {
  font-weight: normal;
  margin: 1.5em 0;
  line-height: 1.5;

  .collapse();

  h2& {
    font-size: 24px;
    font-weight: normal;
  }

  h3& {
    font-size: 20px;
  }

  &.-no-margin {
    margin: 0;
  }

  > small {
    font-size: 14px;
  }
}

.MainContent {
  padding: 32px 32px 64px 32px;
  // [data-icon] {
    // font-size: 20px;
    // vertical-align: top;
  // }

  p {
    font-size: 16px;
    line-height: 1.75;
    margin: 1.75em 0;
    .collapse();
  }

}

a {
  color: @color-blue;
  text-decoration: underline;

  &:hover {
    color: @color-blue-d1;
  }
}

.Table {
  border-collapse: collapse;
  width: 100%;

  > tbody > tr {
    &:not(:first-child) {
      th, td {
        border-top: 1px solid @color-grey;
      }
    }

    th, td {
      padding: 1em;
      font-size: 16px;
      line-height: 1.5;
      text-align: left;
    }

    th {
      white-space: nowrap;
    }
  }

}
