
.PageLayout {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  min-width: 1024px;
}

.MainLayout {
  > ._content {
    // padding: 32px 32px 64px 32px;

    // &.-no-padding {
    //   padding: 0;
    // }
  }
}

.Flex {
  display: flex;

  &.-row {
    flex-direction: row;
  }

  &.-column {
    flex-direction: column;
  }

  &.-align-start {
    align-items: flex-start;
  }

  &.-align-center {
    align-items: center;
  }

  &.-align-end {
    align-items: flex-end;
  }

  &.-justify-start {
    justify-content: flex-start;
  }

  &.-justify-center {
    justify-content: center;
  }

  &.-justify-end {
    justify-content: flex-end;
  }

  &.-justify-between {
    justify-content: space-between;
  }

  &.-gap-1 {
    gap: 8px;
  }

  &.-gap-2 {
    gap: 16px;
  }

  &.-gap-3 {
    gap: 24px;
  }

  &.-gap-4 {
    gap: 32px;
  }

  &.-gap-5 {
    gap: 40px;
  }
}
