@import "./variables.less";

.AboutView {
  .AboutViewItem {
    display: flex;

    &:nth-child(even) {
      flex-direction: row-reverse;
      background-color: @color-grey-l2;
    }

    > ._body {
      flex: 1 1 45%;
      padding: 3rem 2rem;
      min-height: 33vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2rem;

      > h2 {
        font-size: 24px;
        font-weight: normal;
        line-height: 1.5;
        margin: 0;
      }

      > p {
        font-size: 15px;
        line-height: 1.75;
        margin: 0;
      }
    }

    > ._image {
      flex: 1 1 55%;
      background-size: cover;
      background-position: center center;

      > img {
        width: 100%;
        // height: auto;
        height: 100%;
        // min-height: 33vh;
        object-fit: cover;
        vertical-align: top;
      }
    }
  }
}

.ShopView {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .ShopViewTabMenu {
    display: flex;
    gap: 2rem;

    > a {
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem;
      text-decoration: none;
      border-radius: 1rem;
      border: 3px solid @color-grey-l2;
      gap: 1rem;
      transition: all .2s ease 0s;

      &:hover {
        border-color: @color-blue-l1;
        background-color: @color-blue-l3;
        color: @color-blue;
      }

      &.-active {
        background-color: @color-blue;
        border-color: @color-blue;
        color: @color-white;
      }

      > ._label {
        font-size: 40px;
      }

      > ._logo {
        height: 32px;
        width: auto;
        opacity: 0.5;
      }
    }

    &.-small {
      > a {
        padding: 1rem;

        > ._label {
          font-size: 24px;
        }

        > ._logo {
          height: 24px;
          width: auto;
        }
      }
    }
  }

  .ShopViewContent {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    > ._info {
      display: flex;
      gap: 2rem;

      > ._body {
        flex: 1 1 50%;
      }

      > ._photo {
        flex: 1 1 50%;

        > img {
          width: 100%;
          height: auto;
        }
      }
    }

    > ._map {
      > ._mapbase {
        border: 3px solid @color-grey-d3;
        padding: 2rem;
        border-radius: 1rem;
        overflow: hidden;

        > img {
          width: 100%;
          height: auto;
        }

        > ._link {
          background-color: @color-grey-d3;
          margin: -2rem;
          margin-bottom: 0;
          padding: 1rem;
          text-align: right;

          > a {
            color: @color-white;
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            gap: 8px;
            padding: 8px 16px;
            border-radius: 8px;

            &:hover {
              background-color: @color-grey-d2;
            }

            [data-icon] {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}

.NewsPostView {
  display: flex;
  gap: 64px;

  > article {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    > ._heading {
      border-bottom: 4px solid @color-grey-l2;
      padding: 0 0 1rem;

      > time {
        display: block;
        font-size: 14px;
        color: @color-grey-d2;
        margin-top: 0.5rem;
      }
    }

    > ._body {
      font-size: 16px;
      color: @color-black;

      p {
        font-size: inherit;
        line-height: 1.75;
        margin: 1.75em 0;
        .collapse();
      }

      blockquote {
        background-color: @color-grey-l2;
        margin: 1.75em 0;
        padding: 1em;
        line-height: 1.75;
        border-radius: 1em;
        border: 1px solid @color-grey-l1;
        .collapse();
      }

      ul, ol {
        margin: 1.75em 0;
        padding: 0 0 0 2em;
        font-size: inherit;
        line-height: 1.25;
        .collapse();

        > li {
          margin: 1em 0;
          font-size: inherit;
        }
      }

    }
  }

  > aside {
    flex: 0 0 320px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    > ._item {
      > ._heading {
        margin: 0 0 1rem;
        padding: 0 0 1rem;
        border-bottom: 1px dashed @color-grey;
      }
    }
  }

  .NewsAsideIndex {
    > ._list {
      list-style-type: none;
      margin: 0;
      padding: 0;

      > li {
        padding: 0.5rem 0;

        > a {
          display: block;
          font-size: 16px;
          text-decoration: none;
          line-height: 1.5;

          > span {
            text-decoration: underline;
          }

          > time {
            display: block;
            font-size: 14px;
            color: @color-grey-d1;
            margin-top: 0.25rem;
          }

        }

      }
    }
    > ._foot {
      margin: 1rem 0 0;
    }
  }
}

.PurchaseView {
  > ._lead {
    text-align: center;
    font-size: 16px;
    line-height: 1.75;
    padding: 2em 0;
  }
}

.PurchaseProcessView {

  > hr {
    border: none;
    border-top: 1px dashed @color-grey;
    margin: 2rem;
  }

  > ._item {
    display: flex;
    gap: 64px;
    padding: 0 32px;

    > ._text {
      flex: 1 1 60%;
      padding: 24px 0;

      > ._heading {
        font-size: 24px;
        font-weight: normal;
        display: flex;
        color: @color-blue;
        margin: 0;

        > ._number {
          font-family: @font-en;
          display: flex;
          flex: 0 0 2em;
          align-items: center;
          justify-content: center;
          width: 2em;
          height: 2em;
          border: 2px solid currentColor;
          border-radius: 2em;
          box-sizing: border-box;
          margin-right: 0.5em;
        }

        > ._title {
          line-height: 1.5;
          margin-top: 6px;
        }
      }

      > ._content {
        margin: 24px 0 0 60px;

        > p {
          font-size: 16px;
          line-height: 1.75;
          margin: 1.75em 0;
        }
      }
    }

    > ._illust {
      flex: 1 1 40%;
      display: flex;
      align-items: center;

      > img {
        width: 100%;
        height: auto;
      }
    }
  }
}
