@import "./variables.less";

.PageHeader {
  border-top: 4px solid @color-blue-l1;
  position: relative;
  z-index: 1;
}

.PageHeaderHeading {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid @color-grey-l2;
  background-color: white;
  padding: 12px 16px 12px 32px;

  > ._left {
    > ._subhead {
      font-size: 13px;
    }

    > ._logos {
      font-weight: normal;
      margin: 8px 0 0;

      > a {
        display: flex;
        gap: 16px;
        color: @color-blue;
        text-decoration: none;
        font-weight: normal;

        > ._logo {
          > ._svg {
            width: 90px;
            height: 36px;
          }

          > ._label {
            display: block;
            margin-top: 4px;
            font-size: 11px;
            color: @color-blue-d1;
          }
        }
      }

    }
  }

  > ._right {
    display: flex;
    align-self: flex-end;
    align-items: flex-end;
    gap: 48px;

    > ._phones {
      display: flex;
      gap: 20px;

      > ._col {
        > ._label {
          display: block;
          font-size: 13px;
        }

        > ._number {
          display: block;
          font-family: @font-en;
          text-decoration: none;
          color: @color-blue;
          font-size: 32px;
          line-height: 1.25;
        }
      }
    }
  }
}

.PageHeaderNav {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid @color-grey-l2;
  background-color: white;

  > ._main-nav {
    display: flex;
    padding: 8px 16px;
    gap: 16px;

    > a {
      color: @color-black;
      display: flex;
      align-items: center;
      height: 32px;
      font-size: 14px;
      padding: 0 16px;
      text-decoration: none;
      border-radius: 32px;

      &:hover {
        background-color: @color-grey-l3;
      }

      [data-page="about"] &.-about,
      [data-page="shop"] &.-shop,
      [data-page="hearing-aid"] &.-hearing-aid,
      [data-page="purchase"] &.-purchase,
      [data-page="contact"] &.-contact
      {
        background-color: @color-blue-l3;
      }
    }
  }

  > ._social-nav {
    display: flex;
    background-color: @color-grey-l3;
    padding: 8px 16px;
    gap: 16px;

    > a {
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      color: @color-grey-d2;
      border-radius: 4px;

      &:hover {
        color: @color-blue;
      }
    }
  }
}

.PageFooter {
  border-top: 4px solid @color-grey-l2;
}

.PageFooterContactNav {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
  padding: 24px;
  gap: 32px;

  > ._left {
    > ._phones {
      display: flex;
      gap: 24px;

      > ._item {
        display: flex;
        align-items: flex-end;
        gap: 4px;

        > [data-icon] {
          font-size: 32px;
          color: @color-blue-l1;
        }

        > ._content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          > ._label {
            font-size: 13px;
          }

          > ._number {
            font-size: 30px;
            color: @color-blue;
            text-decoration: none;
          }
        }
      }
    }

    > ._info {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      gap: 8px;

      > ._item {
        display: flex;
        align-items: center;
        gap: 8px;

        > ._label {
          display: inline-flex;
          align-items: center;
          font-size: 12px;
          height: 28px;
          box-sizing: border-box;
          border: 1px solid @color-grey-d1;
          padding: 0 4px;
          border-radius: 4px;
        }

        > ._content {
          font-size: 13px;
        }
      }
    }
  }

  > ._right {
    ._buttons {
      display: flex;
      gap: 16px;
    }
  }
}

.PageFooterCredit {
  background-color: @color-grey-d3;
  color: @color-white;

  > ._logos {
    display: flex;
    padding: 40px 0;
    gap: 32px;
    justify-content: center;

    > ._item {
      > ._hearing-studio {
        width: 276px;
        height: 60px;
      }

      > ._khac {
        width: 100px;
        height: 38px;
        margin-top: 6px;
      }

      > ._mhac {
        width: 100px;
        height: 38px;
        margin-top: 6px;
      }
    }
  }

  > ._copyright {
    padding: 0 0 40px;
    text-align: center;
    font-size: 14px;
    color: @color-grey-d2;
  }
}

.HomeVisual {
  display: flex;
  align-items: center;
  height: 40vw;
  min-height: 400px;
  background-image: ~'url(/assets/images/home-visual.png)';
  background-size: cover;
  background-position: center center;
  padding: 0 32px;
  position: relative;

  > ._message {
    font-size: 40px;
    line-height: 1.5;
  }

  > ._suggest {
    position: absolute;
    padding: 32px;
    left: 0;
    bottom: 0;

    > a {
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: @color-white;
      color: @color-blue;
      text-decoration: none;
      border: 1px solid @color-blue;
      padding: 8px 16px 8px 8px;
      border-radius: 8px;

      &:hover {
        background-color: @color-blue-l3;
      }

      > ._date {
        background-color: @color-blue;
        color: @color-white;
        font-size: 14px;
        padding: 8px;
        border-radius: 8px;
        line-height: 1;
      }

      > ._title {
        display: block;
        max-width: 33vw;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.HomeShopButtons {
  > ._inner {
    display: flex;
    padding: 8px;
    gap: 16px;

    > a {
      flex: 1 1 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 24px;
      color: @color-blue;
      gap: 32px;
      text-decoration: none;
      position: relative;
      border-radius: 8px;

      > ._name {
        font-size: 20px;
        line-height: 44px;
        color: @color-black;
      }

      &:hover {
        background-color: @color-blue-l3;
      }

      &:not(:first-child) {
        &::before {
          content: "";
          display: block;
          position: absolute;
          border-left: 1px solid @color-grey;
          height: 64px;
          left: -8px;
        }
      }
    }
  }
}

.HomeNews {
  display: flex;

  > * {
    flex: 1 1 100%;
  }
}

.HomeNewsItem {
  padding: 32px;

  > ._heading {
    font-weight: normal;
    font-size: 20px;
    line-height: 1.5;
    margin: 0;
  }

  > ._list {
    list-style-type: none;
    margin: 24px 0 0;
    padding: 0;

    > ._item {
      // display: flex;
      border-bottom: 1px solid @color-grey;
      // padding: 16px 0;
      // font-size: 16px;
      // gap: 20px;

      > a {
        display: flex;
        padding: 16px 8px;
        font-size: 16px;
        gap: 20px;
        text-decoration: none;

        &:hover {
          background-color: @color-blue-l3;
        }

        > ._date {
          color: @color-grey-d1;
          line-height: 1.5;
        }

        > ._title {
          display: block;
          color: @color-black;
          line-height: 1.5;

        }
      }
      

    }
  }

  > ._link-button {
    margin-top: 24px;
    display: inline-flex;
    align-items: center;
    color: @color-black;
    height: 46px;
    padding: 0 16px;
    border-radius: 8px;
    border: 2px solid @color-grey-l1;
    text-decoration: none;

    &:hover {
      border-color: @color-blue-l1;
    }
  }
}


.HomeMakerList {
  > ._heading {
    display: inline-flex;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    background-color: @color-grey-l2;
    font-size: 14px;
    color: @color-blue-d1;
    font-weight: normal;
    border-radius: 0 0 4px 0;
    margin: 0;
  }

  > ._list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    gap: 8px;
    padding: 0 16px 16px 16px;

    > li {
      flex: 0 0 192px;

      a {
        position: relative;
        display: block;
        text-decoration: none;
        padding: 16px;

        > img {
          width: 160px;
          height: auto;
        }

        ._tooltip {
          position: absolute;
          // min-width: 232px;
          min-width: 160px + 32px;
          left: 0;
          top: calc(100% - 16px);
          box-sizing: border-box;
          background-color: rgba(0, 0, 0, .8);
          pointer-events: none;
          opacity: 0;
          color: @color-white;
          transition: all 0.3s ease 0s;
          padding: 16px;
          border-radius: 8px;
          z-index: 1;

          &::before {
            content: "";
            display: block;
            border: 4px solid transparent;
            border-bottom-color: rgba(0, 0, 0, 0.8);
            width: 8px;
            height: 8px;
            box-sizing: border-box;
            position: absolute;
            left: 50%;
            top: -8px;
          }

          > span {
            display: block;

            &:not(:first-child) {
              margin-top: 8px;
            }
            &._name {
              font-size: 15px;
            }
            &._small {
              font-size: 13px;
              color: @color-grey;
            }
            &._url {
              font-size: 13px;
              color: @color-blue-l1;
            }
          }
        }

        &:hover {
          > img {
            opacity: 0.8;
          }

          ._tooltip {
            opacity: 1;
            top: calc(100% - 4px);
          }
        }
      }


    }
  }
}

.MainHeading {
  padding: 80px 32px;
  background-color: @color-grey-l3;
  border-bottom: 2px solid @color-grey-l2;

  > ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    gap: 4px;

    > li {
      display: flex;
      align-items: center;
      color: @color-blue;
      font-size: 15px;

      > a {
        text-decoration: none;
        color: inherit;
      }
    }

    > li + li {
      &::before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border: 1px solid transparent;
        border-top-color: @color-grey-d2;
        border-right-color: @color-grey-d2;
        transform: rotate(45deg);
        margin-right: 8px;
      }
    }
  }

  > h1 {
    font-size: 32px;
    line-height: 1;
    font-weight: normal;
    margin: 0;
    color: @color-blue-d3;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}

.ContactIndexPhone {
  display: flex;
  flex-direction: column;
  border: 4px solid @color-grey-l1;
  border-radius: 1rem;
  padding: 2rem;
  gap: 1rem;

  > ._list {
    display: flex;
    justify-content: center;
    gap: 64px;

    > ._item {
      ._label {
        font-size: 16px;
        color: @color-black;
        text-align: center;
      }
      ._number {
        display: flex;
        align-items: center;
        gap: 8px;
        color: @color-blue;
        font-size: 40px;
        text-decoration: none;
        margin-top: 8px;

        [data-icon] {
          display: inline-flex;
          opacity: 0.5;
        }

        &:hover {
          color: @color-blue-d1;
        }
      }

    }
  }
  > ._note {
    border-top: 1px solid @color-grey-l1;
    line-height: 1.5;
    padding-top: 1em;
    font-size: 16px;
    text-align: center;
  }
}

.ContactIndexMail {
  display: flex;
  gap: 2rem;

  > ._item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.ContactFaqList {
  margin: 3rem 0;

  > ._item {
    &:not(:first-child) {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 2px solid @color-grey-l1;
    }

    > ._question {
      display: flex;
      gap: 8px;
      font-size: 20px;
      line-height: 2rem;

      &::before {
        content: "Q";
        flex: 0 0 32px;
        display: inline-flex;
        background-color: @color-blue;
        color: white;
        width: 2rem;
        height: 2rem;
        border-radius: 2rem;
        font-size: 16px;
        align-items: center;
        justify-content: center;
      }
    }
    > ._answer {
      margin: 8px 0 0 40px;
      padding-top: 8px;
      border-top: 1px dashed @color-grey-l1;
      font-size: 16px;
      line-height: 1.75;
    }
  }
}
