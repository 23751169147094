@color-black: #222222;
@color-white: #ffffff;

@color-grey-d3: #333333;
@color-grey-d2: #666666;
@color-grey-d1: #999999;
@color-grey: #C6C6C6;
@color-grey-l1: #E2E2E2;
@color-grey-l2: #F0F0F0;
@color-grey-l3: #F8F8F8;

@color-blue-d3: #002833;
@color-blue-d2: #005166;
@color-blue-d1: #007999;
@color-blue: #009DC6;
@color-blue-l1: #7FCDE2;
@color-blue-l2: #BFE6F0;
@color-blue-l3: #E0F3F8;

@color-red-d3: #330015;
@color-red-d2: #66002B;
@color-red-d1: #990040;
@color-red: #C60053;
@color-red-l1: #E27FA9;
@color-red-l2: #F0BFD4;
@color-red-l3: #F8E0EA;

@font-ja: "Noto Sans JP", sans-serif;
@font-en: "Roboto", sans-serif;

.collapse () {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
