@import "./variables.less";

body {
  color: @color-black;
  font-family: @font-ja;
}

* {
  font-family: inherit;
}

.font-ja {
  font-family: @font-ja;
}

.font-en {
  font-family: @font-en;
}

.-mobile-show {
  display: none;
}
