
.-mobile-hidden {
  display: none;
}

.-mobile-show {
  display: block;
}

.PageLayout {
  min-width: 0;
}

.PageHeader {
  display: none;
}

.PageFooterContactNav {
  padding: 16px;
  flex-direction: column;
  > ._left {
    > ._phones {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
    > ._info {
      margin-top: 24px;
      flex-direction: column;
      align-items: center;
    }
  }
  > ._right {
    > ._buttons {
      flex-direction: column;
      gap: 8px;
    }
  }
}

.PageFooterCredit {
  > ._logos {
    padding: 24px 16px;
    flex-wrap: wrap;
    gap: 16px;
  }
}

.Heading {
  h2& {
    font-size: 22px;
  }

  h3& {
    font-size: 20px;
  }
}

.HomeVisual {
  min-height: 100px;
  padding: 0 16px;
  margin-bottom: 32px;

  > ._message {
    font-size: 16px;
  }

  > ._suggest {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    bottom: -40px;
    white-space: nowrap;

    > a {
      > ._title {
        max-width: 100%;
      }
    }
  }
}

.HomeShopButtons {
  > ._inner {
    > a {
      flex-direction: column;
      padding: 8px;
      align-items: center;
      gap: 8px;

      &:nth-child(1) {
        > svg {
          width: 119px;
        }
      }

      &:nth-child(2) {
        > svg {
          width: 130px;
        }
      }

      > svg {
        height: 48px;
      }

      > ._name {
        font-size: 14px;
        line-height: 1;
      }
    }
  }

}

.HomeMakerList {
  > ._heading {
    font-size: 13px;
    height: 24px;
  }
  > ._list {
    padding: 16px;
    overflow: hidden;
    > li {
      flex: 0 0 100px;

      > a {
        padding: 8px;

        > img {
          width: 100px - 16px;
        }
      }
    }
  }
}

.HomeNews {
  flex-direction: column;

  > .HomeNewsItem {
    display: flex;
    flex-direction: column;
    padding: 16px;

    > ._heading {
      align-self: center;
      font-size: 18px;
    }
    > ._list {
      margin-top: 8px;

      > ._item {
        padding: 8px 0;
        font-size: 14px;
      }
    }

    > ._link-button {
      font-size: 14px;
      height: 14px * 3;
      margin-top: 8px;
      align-self: center;
    }
  }
}

.MainContent {
  padding: 24px 16px;
}

.MainHeading {
  padding: 40px 16px;

  > h1 {
    font-size: 24px;
  }
}

.AboutView {
  .AboutViewItem {
    flex-direction: column;

    &:nth-child(even) {
      flex-direction: column;
    }

    > ._body {
      min-height: 0;
      gap: 1rem;
      padding: 2rem 1rem;

      > h2 {
        font-size: 20px;
      }

      > p {
        font-size: 14px;
      }
    }
  }
}

.ShopView {
  gap: 1rem;
  flex-direction: column-reverse;

  .ShopViewTabMenu {
    flex-direction: column;
    gap: 1rem;

    > a {
      padding: 1rem;
      justify-content: flex-start;

      > ._label {
        font-size: 24px;
      }

      > ._logo {
        height: 20px;
        width: auto;
      }
    }

    &.-small {
      > a {
        padding: 1rem;
      }
    }
  }

  .ShopViewContent {
    > ._info {
      flex-direction: column-reverse;
      gap: 1rem;
    }

    > ._map {
      > ._mapbase {
        padding: 0;

        > ._link {
          margin: 0;
          padding: 0;

          > a {
            font-size: 14px;
            padding: 1em;
          }
        }
      }
    }
  }
}

.ContactIndexPhone {
  border: none;
  padding: 0;

  > ._list {
    flex-direction: column;
    gap: 2rem;
  }
}

.ContactIndexMail {
  flex-direction: column;
}

.NewsPostView {
  display: block;

  > aside {
    margin-top: 40px;
    padding-top: 24px;
    border-top: 2px solid @color-grey;
  }
}

.PurchaseView {
  > ._lead {
    padding: 2em 1em;
    text-align: left;
  }
}

.PurchaseProcessView {
  > ._item {
    display: block;
    padding: 0 16px;

    > ._text {
      > ._content {
        margin: 24px 0 0;
      }
    }
  }
}
